import React, { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Link } from "react-router-dom";
import { DatePicker } from "antd";
import Select from "react-select";
import { Calendar } from "feather-icons-react/build/IconComponents";
import {
  getSupplierList,
  getTaxList,
  getDiscountList,
  getUnitList,
  getDiscountbyguid,
} from "../../../services/MasterApiServices";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import { getProductList } from "../../../services/MasterApiServices";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useSelector } from "react-redux";
import { PurchaseClass } from "../../../core/json/Purchase";
import { PurchaseDetailClass } from "../../../core/json/PurchaseDetail";
import toast from "react-hot-toast";
import {
  addEditPurchase,
  getPurchaseByTransType,
} from "../../../services/PurchaseApiService";
import SupplierModal from "../../master/modal/SupplierModal";
import { ProductClass } from "../../../core/json/Product";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import { DiscountClass } from "../../../core/json/Discount";
import { TaxClass } from "../../../core/json/TaxClass";
import { useDispatch } from "react-redux";
import {
  fetchUnit,
  fetchProduct,
  fetchTax,
  fetchSupplier,
  fetchSettings,
} from "../../../redux/userSetting";

const PurchaseInvoiceModal = ({ mode, data, handleClose, handleRefresh }) => {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      date: dayjs().format("YYYY-MM-DD"),
      deliveryDate: dayjs().format("YYYY-MM-DD"),
      invoiceDate: dayjs().format("YYYY-MM-DD"),
      duedate: dayjs().format("YYYY-MM-DD"),
      addition: 0,
      subtraction: 0,
      round: 0,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUnit());
    dispatch(fetchProduct(branch));
    dispatch(fetchTax());
    dispatch(fetchSettings(branch));
    const match = settingList?.currencyFormat?.toString()?.match(/\.0+/);
    const currfmt = match ? match[0].length - 1 : 0;
    setDecimalPoints(currfmt);
    dispatch(fetchSupplier());
  }, []);
  const [taxData, setTaxData] = useState([]);
  const [discountData, setdiscountData] = useState([]);
  const [unitData, setunitData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isRowAdded, setIsRowAdded] = useState(false);
  const [decimalpoints, setDecimalPoints] = useState(2);

  const [products, setProducts] = useState([]);
  const [discountList, setdiscountList] = useState([]);

  // Get user from redux
  const { userId } = useSelector((state) => state.userauth);

  const { productList, unitList, taxList, supplierList, settingList } =
    useSelector((state) => state.usersetting);
  console.log(supplierList, "daaaaata");

  // Get branch from redux
  const { branch } = useSelector((state) => state.branchlist);

  const paymentType = [
    { value: 0, label: "Cash" },
    { value: 1, label: "Card" },
    { value: 2, label: "Bank" },
    { value: 3, label: "Credit" },
    { value: 4, label: "UPI" },
  ];
  const referencetype = [
    { value: 0, label: "Direct" },
    { value: 1, label: "Order" },
    { value: 2, label: "Goods Receipt" },
  ];
  // const purchaseTransType = [
  //   { value: 0, label: "Order" },
  //   { value: 1, label: "Goods Receipt" },
  //   { value: 2, label: "Invoice" },
  //   { value: 3, label: "Return" },
  // ];

  //Calculations ---------------
  const safeNumber = (value, defaultValue = 0) => {
    // Check for null, undefined, or empty string and use the default value
    if (value === null || value === undefined || value === "") {
      return defaultValue;
    }

    // Convert to number
    const num = Number(value);

    // Return the number or the default value if num is NaN
    return isNaN(num) ? defaultValue : num;
  };
  const rowUseForm = getValues("row") || [];

  let totalGross = 0;
  let totalDiscount = 0;
  let totalTax = 0;
  let totalNet = 0;

  fields.forEach((field, index) => {
    const quantity = watch(`items[${index}].quantity`, 0);
    const price = watch(`items[${index}].price`, 0);
    const discount = watch(`items[${index}].discountPercentage`, 0);
    const tax = watch(`items[${index}].taxPercentage`, 0);

    const grossTl = price * quantity || 0;
    const discountAmt = (grossTl * discount) / 100 || 0;
    const amountBeforeTax = grossTl - discountAmt || 0;
    const taxAmount = (amountBeforeTax * tax) / 100 || 0;
    const netTotal = amountBeforeTax + taxAmount || 0;

    setValue(`items[${index}].gross`, grossTl?.toFixed(decimalpoints));
    setValue(
      `items[${index}].discountAmount`,
      discountAmt?.toFixed(decimalpoints)
    );
    setValue(`items[${index}].taxAmount`, taxAmount?.toFixed(decimalpoints));
    setValue(`items[${index}].netTotal`, netTotal?.toFixed(decimalpoints));

    // Add to totalGross
    totalGross += grossTl;
    totalDiscount += discountAmt;
    totalTax += taxAmount;
    totalNet += netTotal;
  });

  //Final calculations on addition ,discount and round
  const addition = Number(watch(`addition`, 0));
  const subtraction = Number(watch(`subtraction`, 0));
  const round = Number(watch(`round`, 0));

  totalNet += safeNumber(addition);
  totalNet -= safeNumber(subtraction);
  totalNet -= safeNumber(round);
  totalDiscount = totalDiscount + safeNumber(subtraction);
  //end-------------

  const handleUnitList = async () => {
    try {
      const response = await getUnitList();
      const formattedUnit = response?.map((unit) => ({
        label: unit.name,
        value: unit.guid,
      }));
      setunitData(formattedUnit);
    } catch (error) {
      console.error(
        "Error fetching unit",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };
  const handleTaxList = async () => {
    try {
      const formattedTax = taxList?.map((tax) => ({
        label: tax.percentage,
        value: tax.percentage,
        guid: tax.guid,
      }));
      setTaxData(formattedTax);
    } catch (error) {
      console.error(
        "Error fetching tax",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  const handleDiscountList = async () => {
    try {
      const response = await getDiscountList();

      const formattedDiscount = response?.map((discount) => ({
        label: discount.percentage,
        value: discount.percentage,
        guid: discount.guid,
      }));
      setdiscountData(formattedDiscount);
      setdiscountList(response);
    } catch (error) {
      console.error(
        "Error fetching discount",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  const handleEachProductfetch = async (selectedOption, index) => {
    setValue(`items[${index}].product`, selectedOption.value);

    try {
      const productData = await getSingleProduct(selectedOption.value);

      setValue(`items[${index}].code`, productData?.code || 0);
      setValue(`items[${index}].hsnCode`, productData?.hsnCode || 0);
      setValue(`items[${index}].unit`, productData?.unit.guid || 0);
    } catch (error) {
      console.error(
        "Error fetching product by GUID:",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  async function getSingleProduct(guid) {
    try {
      const filteredData = productList?.filter(
        (product) => product.guid === guid
      );
      return filteredData[0];
    } catch (error) {
      console.error("Error fetching products", error);
    }
  }

  const handleSupplierList = async () => {
    try {
      const formattedSuppliers = supplierList?.map((supplier) => ({
        label: supplier.name,
        value: supplier.guid,
      }));
      setSupplierData(formattedSuppliers);
    } catch (error) {
      console.error("Error fetching suppliers", error);
    }
  };

  // Fetch purchase invoices data
  const handleEntryNo = async () => {
    try {
      const response = await getPurchaseByTransType(branch, 2);
      const purchaseData = response.data;
      // Find the maximum number from the existing entries
      const maxEntryNumber =
        purchaseData.length > 0
          ? purchaseData
              .map((data) => parseInt(data.entrynumber.replace(/\D/g, ""))) // Extract and parse numbers
              .reduce((max, num) => Math.max(max, num), 0) // Find the maximum number
          : 0;
      let counter = maxEntryNumber + 1;
      setValue(`entrynumber`, `PI${counter}`);
      counter++;
    } catch (error) {
      console.error("Failed to fetch purchase invoices", error);
    }
  };
  useEffect(() => {
    if (mode === "add") {
      handleEntryNo();
      if (!isRowAdded && fields.length === 0) {
        handleAddRow();
        setIsRowAdded(true);
      }
    }

    handleProductsList();

    handleTaxList();
    handleUnitList();
    handleDiscountList();
  }, []);

  function resetmodal() {
    if (mode === "edit" && data) {
      // Populate form with data for editing
      //purchase fields population
      setValue("entrynumber", data.entrynumber);
      setValue("remarks", data.remarks);
      setValue("refNo", data.refNo);
      setValue("shipmentNo", data.shipmentNo);
      setValue("shipmentTerms", data.shipmentTerms);
      setValue("invoiceNo", data.invoiceNo);
      setValue("addition", data.addition);
      setValue("subtraction", data.discount);
      setValue("round", data.roundOff);
      setValue("grossTotal", data.grossTotal);
      setValue("discountTotal", data.discountTotal);
      setValue("taxTotal", data.taxTotal);
      setValue("netTotal", data.netTotal);
      // selectboxes
      setValue("paymentType", data.purchasePaymentType);
      console.log(data.referencetype, "data.referencetype");

      setValue("referencetype", data.referencetype || 0);
      setValue("supplier", data.supplier.guid);
      //dates

      setValue("date", dayjs(data.date));
      setValue("invoiceDate", dayjs(data.invoiceDate));
      setValue("duedate", dayjs(data.duedate) || dayjs().format("YYYY-MM-DD"));

      setValue(
        "items",
        data?.purchaseDetails?.map((detail, i) => ({
          product: detail?.product?.guid,
          code: detail?.product?.code,
          hsnCode: detail?.product?.hsnCode || 0,
          batch: detail.batch,
          expiry: dayjs(detail.expiry),
          unit: detail.unit?.guid,
          quantity: detail.quantity,
          price: detail.price,
          mrp: detail.mrp,
          foc: detail.foc,
          discountPercentage: detail.discount?.percentage,
          discountAmount: detail.discountAmount,
          taxPercentage: detail.tax?.percentage,
          gross: detail.gross,
          taxAmount: detail.taxAmount,
          netTotal: detail.netTotal,
        }))
      );
    }
  }

  useEffect(() => {
    resetmodal();
  }, [data, setValue]);

  useEffect(() => {
    handleSupplierList();
  }, [supplierList]);

  const handleProductsList = async () => {
    try {
      const formattedProducts = productList?.map((product) => ({
        label: product.name,
        value: product.guid,
      }));
      setProducts(formattedProducts);
    } catch (error) {
      console.error(
        "Error fetching products",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  const handleAddRow = () => {
    append({
      batch: "0",
      code: "0",
      discountAmount: "0.0",
      discountPercentage: "0.0",
      expiry: dayjs().format("YYYY-MM-DD"),
      foc: "0.0",
      gross: "0.0",
      hsnCode: 0,
      mrp: "0.0",
      netTotal: "0.0",
      price: "0.0",
      quantity: "0",
      taxAmount: "0.0",
      taxPercentage: "0",
      unit: "",
      product: null,
    });
  };

  const handleFormSubmit = async (formdata) => {
    const objPurchase = new PurchaseClass();
    if (mode === "edit") {
      console.log("edit mode on--------");
      console.log(data.guid, "data.guid");

      objPurchase.guid = data.guid;
      objPurchase.id = data.id;
    }
    objPurchase.branch = branch;
    objPurchase.date = formdata.date
      ? dayjs(formdata.date).format("YYYY-MM-DDTHH:mm:ss")
      : dayjs().format("YYYY-MM-DD");
    objPurchase.purchasePaymentType = formdata.paymentType;
    objPurchase.purchaseTransType = 2;

    // Get supplier data asynchronously

    objPurchase.supplier = supplierList?.find(
      (e) => e.guid == formdata.supplier
    );
    objPurchase.updatedUser = `{${userId?.id}}`;
    objPurchase.invoiceDate = formdata.invoiceDate
      ? dayjs(formdata.invoiceDate).format("YYYY-MM-DDTHH:mm:ss")
      : dayjs().format("YYYY-MM-DD");
    objPurchase.invoiceNo = formdata.invoiceNo || "0";
    objPurchase.refNo = formdata.refNo || "0";
    objPurchase.shipmentNo = formdata.shipmentNo || "0";
    objPurchase.shipmentTerms = formdata.shipmentTerms || "";
    objPurchase.remarks = formdata.remarks || "";
    objPurchase.addition = formdata.addition || 0.0;
    objPurchase.discount = formdata.subtraction || 0.0;
    objPurchase.roundOff = formdata.round || 0.0;
    objPurchase.grossTotal = totalGross || 0;
    objPurchase.discountTotal = totalDiscount || 0.0;
    objPurchase.taxTotal = totalTax || 0.0;
    objPurchase.netTotal = totalNet || 0.0;
    objPurchase.referencetype = formdata.referencetype || 0;
    objPurchase.entrynumber = formdata.entrynumber || 0;
    objPurchase.duedate = formdata.duedate
      ? dayjs(formdata.duedate).format("YYYY-MM-DDTHH:mm:ss")
      : dayjs().format("YYYY-MM-DD");

    console.log(formdata.items, "formdata.items-----");
    const detailObj = formdata?.items?.map((e) => {
      console.log("Processing item:", e);
      const defaultDiscountobj = new DiscountClass();
      const defaultTaxobj = new TaxClass();

      let product;
      const productValue = e.product || e.product.value;
      console.log("productValue", productValue);
      product = productList.find((item) => item.guid == productValue);
      const unit = unitList.find((item) => item.guid == e.unit);
      const discountValue = discountData?.find(
        (discount) => discount.value === e.discountPercentage
      );
      const discount = discountValue
        ? discountList?.find((item) => item.guid == discountValue.guid)
        : defaultDiscountobj;
      const taxValue = taxData?.find((tax) => tax.value === e.taxPercentage);
      const tax = taxValue
        ? taxList?.find((item) => item.guid == taxValue.guid)
        : defaultTaxobj;

      const expiry = e.expiry
        ? dayjs(e.expiry).format("YYYY-MM-DDTHH:mm:ss")
        : dayjs().format("YYYY-MM-DDTHH:mm:ss");

      const detailObj = {
        product,
        unit,
        batch: e.batch,
        expiry,
        quantity: e.quantity,
        price: e.price,
        mrp: e.mrp,
        foc: e.foc,
        taxAmount: e.taxAmount,
        grossAmount: e.gross,
        netAmount: e.netTotal,
        discountAmount: e.discountAmount,
        discount,
        tax,
      };

      console.log("Detail object constructed:", detailObj);

      return detailObj;
    });
    objPurchase.purchaseDetails = detailObj;
    try {
      console.log(objPurchase, "-------------------objPurchase");
      const response = await addEditPurchase(objPurchase);
      if (response) {
        toast.success("Success");
        handleRefresh(); // Refresh the list
        handleClose(); // Close the modal
      }
    } catch (error) {
      toast.error(error?.response?.data?.Message);
      console.error(
        "Error handling :",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  return (
    <div>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
      >
        <div className="modal-dialog add-centered">
          <div className="modal-content">
            <div className="page-wrapper p-0 m-0">
              <div className="content p-0">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>
                      {" "}
                      {mode === "edit"
                        ? "Edit Purchase Invoice"
                        : "Add Purchase Invoice"}
                    </h4>
                  </div>
                  <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                      <div className="row">
                        {/* row1 */}
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Entry No.</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("entrynumber")}
                              required
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>Date</label>
                            <div className="input-groupicon calender-input">
                              <Calendar className="info-img" />
                              <Controller
                                control={control}
                                name="date"
                                render={({ field: { onChange, value } }) => (
                                  <DatePicker
                                    className="datetimepicker"
                                    value={value ? dayjs(value) : dayjs()}
                                    format="DD-MM-YYYY"
                                    onChange={(date) =>
                                      onChange(
                                        date ? date.format("YYYY-MM-DD") : null
                                      )
                                    }
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Remarks</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("remarks")}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">
                              Payment Type<span className="text-danger">*</span>
                            </label>
                            <div className="row">
                              <Controller
                                control={control}
                                name={`paymentType`}
                                render={({ field: { onChange, value } }) => (
                                  <Select
                                    classNamePrefix="react-select"
                                    options={paymentType}
                                    value={paymentType?.find(
                                      (paymentType) =>
                                        paymentType?.value === value
                                    )}
                                    onChange={(selectedOption) =>
                                      onChange(selectedOption.value)
                                    }
                                    styles={{
                                      menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999,
                                      }),
                                    }}
                                    required
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>

                        {/* row 2 */}
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">
                              Reference Type
                              <span className="text-danger">*</span>
                            </label>
                            <div className="row">
                              <Controller
                                control={control}
                                name={`referencetype`}
                                render={({ field: { onChange, value } }) => (
                                  <Select
                                    classNamePrefix="react-select"
                                    options={referencetype}
                                    value={referencetype?.find(
                                      (reference) => reference?.value === value
                                    )}
                                    onChange={(selectedOption) =>
                                      onChange(selectedOption.value)
                                    }
                                    required
                                  />
                                )}
                              />
                              <div className="col-lg-2 col-sm-2 col-2 ps-0"></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Reference No.</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("refNo")}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>Invoice Date</label>
                            <div className="input-groupicon calender-input">
                              <Calendar className="info-img" />
                              <Controller
                                control={control}
                                name="invoiceDate"
                                render={({ field: { onChange, value } }) => (
                                  <DatePicker
                                    className="datetimepicker"
                                    value={value ? dayjs(value) : dayjs()} // Set value or default to today
                                    format="DD-MM-YYYY"
                                    onChange={(date) =>
                                      onChange(
                                        date ? date.format("YYYY-MM-DD") : null
                                      )
                                    } // Update on change
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Invoice No.</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("invoiceNo")}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks add-product">
                            <label>
                              Supplier Name
                              <span className="text-danger">*</span>
                            </label>
                            <div className="row">
                              <div className="col-lg-10 col-sm-10 col-10">
                                <Controller
                                  control={control}
                                  name={`supplier`}
                                  render={({ field: { onChange, value } }) => (
                                    <Select
                                      classNamePrefix="react-select"
                                      options={supplierData}
                                      value={supplierData?.find(
                                        (supplier) => supplier?.value === value
                                      )}
                                      onChange={(selectedOption) =>
                                        onChange(selectedOption.value)
                                      }
                                      styles={{
                                        menu: (provided) => ({
                                          ...provided,
                                          zIndex: 9999,
                                        }),
                                      }}
                                      required
                                    />
                                  )}
                                />
                              </div>
                              <div className="col-lg-2 col-sm-2 col-2 ps-0">
                                <div className="add-icon tab">
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      setModalOpen(true);
                                    }}
                                  >
                                    <PlusCircle className="feather-plus-circles" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* row 3 */}

                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Shipment No.</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("shipmentNo")}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Shipment Term</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("shipmentTerms")}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>Due Date</label>
                            <div className="input-groupicon calender-input">
                              <Calendar className="info-img" />
                              <Controller
                                control={control}
                                name="duedate"
                                render={({ field: { onChange, value } }) => (
                                  <DatePicker
                                    className="datetimepicker"
                                    value={value ? dayjs(value) : dayjs()} // Set value or default to today
                                    format="DD-MM-YYYY"
                                    onChange={(date) =>
                                      onChange(
                                        date ? date.format("YYYY-MM-DD") : null
                                      )
                                    } // Update on change
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-10">
                            <div className="input-blocks">
                              <label>Product Name</label>
                              <input
                                type="text"
                                placeholder="Please type product code and select"
                              />
                            </div>
                          </div>
                        </div>
                        {/* end of rows */}
                      </div>

                      <div className="table-responsive  no-pagination table-container">
                        <table className="table tablezind">
                          <thead>
                            <tr>
                              <th className="sticky-header">
                                Description
                                <span className="text-danger">*</span>
                              </th>
                              <th className="sticky-header">Item Code</th>
                              <th className="sticky-header">HSN Code</th>
                              <th className="sticky-header">Unit</th>
                              <th className="sticky-header">Batch</th>
                              <th className="sticky-header">Exp Date</th>
                              <th className="sticky-header">
                                Quantity<span className="text-danger">*</span>
                              </th>
                              <th className="sticky-header">
                                Price <span className="text-danger">*</span>
                              </th>
                              <th className="sticky-header">MRP </th>
                              <th className="sticky-header">FOC</th>
                              <th className="sticky-header">Gross</th>
                              <th className="sticky-header">Discount %</th>
                              <th className="sticky-header">Discount Amount</th>
                              <th className="sticky-header">Tax %</th>
                              <th className="sticky-header">Tax Amount</th>
                              <th className="sticky-header">Net Amount</th>
                              <th className="sticky-header">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map((obj, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <Controller
                                      control={control}
                                      name={`items[${index}].product`}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <Select
                                          classNamePrefix="react-select"
                                          options={products}
                                          value={products?.find(
                                            (option) => option.value === value
                                          )}
                                          onChange={(selectedOption) => {
                                            onChange(selectedOption.value);
                                            handleEachProductfetch(
                                              selectedOption,
                                              index
                                            );
                                          }}
                                          styles={{
                                            menu: (provided) => ({
                                              ...provided,
                                              zIndex: 9999,
                                              position: "relative",
                                            }),
                                          }}
                                          required
                                        />
                                      )}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].code`)}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].hsnCode`)}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <Controller
                                      control={control}
                                      name={`items[${index}].unit`}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <Select
                                          classNamePrefix="react-select"
                                          options={unitData}
                                          value={unitData?.find(
                                            (option) => option.value === value
                                          )}
                                          onChange={(selectedOption) => {
                                            onChange(selectedOption.value);
                                          }}
                                          isDisabled={true}
                                        />
                                      )}
                                    />
                                  </td>

                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].batch`)}
                                    />
                                  </td>
                                  <td>
                                    <Controller
                                      control={control}
                                      name={`items[${index}].expiry`}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <DatePicker
                                          className="datetimepicker"
                                          placeholder={
                                            value ? dayjs(value) : dayjs()
                                          }
                                          value={value ? dayjs(value) : dayjs()}
                                          format="DD-MM-YYYY"
                                          onChange={(date) => onChange(date)}
                                        />
                                      )}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].quantity`, {
                                        required: "Quantity is required",
                                        validate: (value) =>
                                          (!isNaN(value) && value > 0) ||
                                          "Quantity must be a positive number",
                                      })}
                                    />
                                    {errors.items &&
                                      errors.items[index]?.quantity && (
                                        <span className="text-danger small">
                                          {errors.items[index].quantity.message}
                                        </span>
                                      )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].price`, {
                                        required: "Price is required",
                                        validate: (value) =>
                                          (!isNaN(value) && value > 0) ||
                                          "Price must be a positive number",
                                      })}
                                    />
                                    {errors.items &&
                                      errors.items[index]?.price && (
                                        <span className="text-danger small">
                                          {errors.items[index].price.message}
                                        </span>
                                      )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].mrp`, {
                                        validate: (value) =>
                                          (!isNaN(value) && value >= 0) ||
                                          "MRP must be zero or more",
                                      })}
                                    />
                                    {errors.items &&
                                      errors.items[index]?.mrp && (
                                        <span className="text-danger small">
                                          {errors.items[index].mrp.message}
                                        </span>
                                      )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].foc`, {
                                        validate: (value) =>
                                          (!isNaN(value) && value >= 0) ||
                                          "FOC must be zero or more",
                                      })}
                                    />
                                    {errors.items &&
                                      errors.items[index]?.foc && (
                                        <span className="text-danger small">
                                          {errors.items[index].foc.message}
                                        </span>
                                      )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].gross`)}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <Controller
                                      control={control}
                                      name={`items[${index}].discountPercentage`}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <Select
                                          classNamePrefix="react-select"
                                          options={discountData}
                                          value={discountData?.find(
                                            (option) => option.value === value
                                          )}
                                          onChange={(selectedOption) =>
                                            onChange(selectedOption.value)
                                          }
                                        />
                                      )}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(
                                        `items[${index}].discountAmount`
                                      )}
                                      disabled
                                    />
                                  </td>

                                  <td>
                                    <Controller
                                      control={control}
                                      name={`items[${index}].taxPercentage`}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <Select
                                          classNamePrefix="react-select"
                                          options={taxData}
                                          value={taxData?.find(
                                            (option) => option.value === value
                                          )}
                                          onChange={(selectedOption) => {
                                            onChange(selectedOption.value);
                                          }}
                                          styles={{
                                            menu: (provided) => ({
                                              ...provided,
                                              zIndex: 9999,
                                              position: "relative",
                                            }),
                                          }}
                                        />
                                      )}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].taxAmount`)}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].netTotal`)}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <Link
                                      className="confirm-text p-2"
                                      onClick={() => remove(index)}
                                    >
                                      <i
                                        data-feather="trash-2"
                                        className="feather-trash-2"
                                      ></i>
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>

                      <div>
                        <div className="col-lg-2 col-sm-2 col-2 ps-0 ">
                          <button
                            className=" btn btn-dark add-icon tab  mt-3 "
                            type="button"
                            onClick={handleAddRow}
                          >
                            <PlusCircle className="feather-plus-circles" />
                          </button>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className=" col-4 ">
                          <div className="input-blocks">
                            <label>Addition</label>
                            <div className="input-groupicon select-code">
                              <input
                                type="text"
                                className="form-control p-2"
                                defaultValue={0}
                                {...register(`addition`)}
                              />
                              {errors.addition && (
                                <span className="text-danger small mt-1">
                                  {errors.addition.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className=" col-4 ">
                          <div className="input-blocks">
                            <label>Discount</label>
                            <div className="input-groupicon select-code">
                              <input
                                type="text"
                                className="form-control p-2"
                                defaultValue={0}
                                {...register(`subtraction`)}
                              />
                              {errors.subtraction && (
                                <span className="text-danger small mt-1">
                                  {errors.subtraction.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className=" col-4 ">
                          <div className="input-blocks">
                            <label>Round Off</label>
                            <div className="input-groupicon select-code">
                              <input
                                type="text"
                                className="form-control p-2"
                                defaultValue={0}
                                {...register(`round`)}
                              />
                              {errors.round && (
                                <span className="text-danger small mt-1">
                                  {errors.round.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4 ms-auto">
                          <div className="total-order w-100 max-widthauto m-auto mb-4">
                            <ul>
                              <li>
                                <h4>Gross</h4>
                                <h5>$ {totalGross.toFixed(decimalpoints)}</h5>
                              </li>
                              <li>
                                <h4>Discount</h4>
                                <h5>
                                  $ {totalDiscount.toFixed(decimalpoints)}
                                </h5>
                              </li>
                              <li>
                                <h4>Tax Total</h4>
                                <h5>$ {totalTax.toFixed(decimalpoints)}</h5>
                              </li>
                              <li>
                                <h4>Net Total</h4>
                                <h5>$ {totalNet.toFixed(decimalpoints)}</h5>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-12 text-end">
                          <button
                            type="button"
                            className="btn btn-cancel add-cancel me-3"
                            onClick={() => resetmodal()}
                          >
                            Reset
                          </button>
                          <button
                            className="btn btn-submit add-sale"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalOpen && (
        <SupplierModal
          mode="add"
          data={{}}
          handleClose={() => setModalOpen(false)}
          handleRefresh={() => {}}
        />
      )}
    </div>
  );
};
export default PurchaseInvoiceModal;
