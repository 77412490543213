import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  PlusCircle,
  RotateCcw,
  ChevronUp,
  Calendar,
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import {
  deletePurchase,
  getPurchaseByTransType,
} from "../../services/PurchaseApiService";
import GoodsReceiptModal from "../../components/transactions/modal/GoodsReceiptModal";
import { OverlayTrigger, TabPane, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setheadertoggle } from "../../redux/toggle";
import { PurchaseClass } from "../../core/json/Purchase";
import dayjs from "dayjs";
import {
  exportExcel,
  exportPDF,
  HandlePrint,
  renderTooltip,
} from "../../redux/helper";
import { getJSON } from "jquery";
import { DatePicker, Select, Tabs } from "antd";
import {
  // fetchUnit,
  // fetchProduct,
  // fetchTax,
  fetchSupplier,
  // fetchSettings,
} from "../../redux/userSetting";

const GoodsReceipt = () => {
  const [searchText, setSearchText] = useState("");
  const [purchaseData, setPurchaseData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [modalMode, setModalMode] = useState("add");
  const [modalOpen, setModalOpen] = useState(false);
  //
  const [itemwiseData, setitemwiseData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const { togglehead } = useSelector((state) => state.headerToggle);
  const {
    // productList,
    // customerList,
    formatedproductList,
    formatedsupplierList,
  } = useSelector((state) => state.usersetting);
  // Get branch from redux
  const { branch } = useSelector((state) => state.branchlist);
  const { TabPane } = Tabs;
  // Get user from redux
  const { userId } = useSelector((state) => state.userauth);

  const dispatch = useDispatch();

  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };

  useEffect(() => {
    handlePurchaseData();
    dispatch(fetchSupplier());
  }, [branch]);

  // Fetch goods receipt data
  const handlePurchaseData = async () => {
    try {
      console.log("response");
      const response = await getPurchaseByTransType(branch, 1);
      const invoice = Array.isArray(response.data) ? response.data : [];
      setPurchaseData(invoice);
      setFilteredData(invoice);
      let serialCounter = 1;
      const itemwiseData = (invoice) => {
        return invoice.purchaseDetails.map((item, index) => {
          return {
            key: serialCounter,
            slno: serialCounter++,
            date: invoice.date,
            entrynumber: invoice.entrynumber,
            supplier: invoice.supplier?.name || "Unknown Supplier",
            itemName: item.product?.name || "Unknown Item",
            quantity: item.quantity,
            rate: item.price,
            total: item.netAmount,
            discountTotal: item.discountAmount,
            tax: item.taxAmount,
            netAmount: item.netAmount,
          };
        });
      };

      const allItemwiseData = invoice.flatMap((invoice) =>
        itemwiseData(invoice)
      );
      setitemwiseData(allItemwiseData);
    } catch (error) {
      console.error(
        "Failed to fetch goods receipt",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  // const filteredData = purchaseData
  //   .filter((entry) => {
  //     return Object.keys(entry).some((key) => {
  //       return String(entry[key])
  //         .toLowerCase()
  //         .includes(searchText.toLowerCase());
  //     });
  //   })
  //   .sort((a, b) => b.createdAt - a.createdAt); // Ensure new items appear on top

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  // const modifiedList = purchaseData?.filter(
  //   (value) =>
  //     value.name?.toLowerCase().includes(searchText?.toLowerCase()) ||
  //     value.code?.toLowerCase().includes(searchText?.toLowerCase())
  // );

  function handleFilter() {
    console.log("Original purchaseData:", purchaseData);
    console.log("Search Text:", searchText);
    console.log("From Date:", fromDate, "To Date:", toDate);
    console.log("Selected Product:", selectedProduct);
    console.log("Selected Supplier:", selectedSupplier);

    const filteredData = purchaseData.filter((entry) => {
      console.log("Current Entry:", entry);
      const supplierMatch =
        !selectedSupplier || entry.supplier?.guid === selectedSupplier;
      const productMatch =
        !selectedProduct ||
        entry?.purchaseDetails?.some(
          (detail) => detail?.product?.guid === selectedProduct
        );
      const fromDateMatch =
        !fromDate || new Date(entry.date) >= new Date(fromDate);
      const toDateMatch = !toDate || new Date(entry.date) <= new Date(toDate);

      const searchTextMatch =
        entry?.date?.toLowerCase().includes(searchText?.toLowerCase()) ||
        entry?.supplier?.name
          ?.toLowerCase()
          .includes(searchText?.toLowerCase()) ||
        entry?.invoiceDate?.toLowerCase().includes(searchText?.toLowerCase()) ||
        entry?.referenceNumber
          ?.toLowerCase()
          .includes(searchText?.toLowerCase()) ||
        entry?.status?.toLowerCase().includes(searchText?.toLowerCase()) ||
        entry?.entrynumber?.toLowerCase().includes(searchText?.toLowerCase()) ||
        entry?.netTotal?.toString().includes(searchText);

      return (
        supplierMatch &&
        productMatch &&
        fromDateMatch &&
        toDateMatch &&
        searchTextMatch
      );
    });

    console.log("filtered Data:", filteredData);
    setFilteredData(filteredData);
  }

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Number",
      dataIndex: "entrynumber",
      sorter: (a, b) => a.entryNumber.length - b.entryNumber.length,
    },
    {
      title: "Supplier",
      dataIndex: "supplier.name",
      sorter: (a, b) => a.supplier.length - b.supplier.length,
      render: (_, record) => record?.supplier?.name,
    },
    {
      title: "GRN No",
      dataIndex: "invoiceNo",
      sorter: (a, b) => a.invoiceNo.length - b.invoiceNo.length,
    },
    {
      title: "Delivery Date",
      dataIndex: "duedate",
      sorter: (a, b) => (dayjs(a.duedate).isAfter(dayjs(b.duedate)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Status",
      dataIndex: "purchasePaymentType",
      render: (purchasePaymentType) => {
        let badgeClass = "";
        let text = "";

        switch (purchasePaymentType) {
          case 0:
            text = "Cash";
            badgeClass = "bg-lightyellow";
            break;
          case 1:
            text = "Card";
            badgeClass = "bg-lightpurple";
            break;
          case 2:
            text = "Bank";
            badgeClass = "bg-lightblue";
            break;
          case 3:
            text = "Credit";
            badgeClass = "bg-lightred";
            break;
          case 4:
            text = "UPI";
            badgeClass = "bg-lightgreen";
            break;
          default:
            text = "Unknown";
            badgeClass = "";
        }

        return <span className={`badges ${badgeClass}`}>{text}</span>;
      },
      sorter: (a, b) =>
        a.purchasePaymentType.length - b.purchasePaymentType.length,
    },
    {
      title: "Net Amount",
      dataIndex: "netTotal",
      sorter: (a, b) => a.netTotal.length - b.netTotal.length,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, obj) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link className="me-2 p-2" to="#">
              <i data-feather="eye" className="feather-eye"></i>
            </Link>
            {/* Edit Button */}
            <Link
              className="me-2 p-2"
              onClick={() => handleEditClick(obj.guid)}
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            {/* Delete Button */}
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => handleDeleteClick(obj)}
            >
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];
  const Reportcolumns = [
    {
      title: "Sl No.",
      dataIndex: "no",
      sorter: (a, b) => a.no.length - b.no.length,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Bill No",
      dataIndex: "entrynumber",
      sorter: (a, b) => a.entryNumber.length - b.entryNumber.length,
    },
    {
      title: "Supplier",
      dataIndex: "supplier.name",
      sorter: (a, b) => a.supplier.length - b.supplier.length,
      render: (_, record) => record?.supplier?.name,
    },
    {
      title: "Total",
      dataIndex: "grossTotal",
      sorter: (a, b) => a.grossTotal.length - b.grossTotal.length,
    },
    {
      title: "Discount",
      dataIndex: "discountTotal",
      sorter: (a, b) => a.discountTotal.length - b.discountTotal.length,
    },
    {
      title: "Tax",
      dataIndex: "taxTotal",
      sorter: (a, b) => a.taxTotal.length - b.taxTotal.length,
    },
    {
      title: "Net",
      dataIndex: "netTotal",
      sorter: (a, b) => a.netTotal.length - b.netTotal.length,
    },
  ];
  const supplierColumns = [
    {
      title: "Sl No.",
      dataIndex: "no",
      sorter: (a, b) => a.no.length - b.no.length,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Bill No",
      dataIndex: "entrynumber",
      sorter: (a, b) => a.entryNumber.length - b.entryNumber.length,
    },
    {
      title: "Supplier",
      dataIndex: "supplier.name",
      sorter: (a, b) => a.supplier.length - b.supplier.length,
      render: (_, record) => record?.supplier?.name,
    },
    {
      title: "Invoice No.",
      dataIndex: "invoiceNo",
      sorter: (a, b) => a.invoiceNo.length - b.invoiceNo.length,
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      sorter: (a, b) =>
        dayjs(a.invoiceDate).isAfter(dayjs(b.invoiceDate)) ? 1 : -1,
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Total",
      dataIndex: "grossTotal",
      sorter: (a, b) => a.grossTotal.length - b.grossTotal.length,
    },
    {
      title: "Discount",
      dataIndex: "discountTotal",
      sorter: (a, b) => a.discountTotal.length - b.discountTotal.length,
    },
    {
      title: "Tax",
      dataIndex: "invoiceDate",
      sorter: (a, b) =>
        dayjs(a.invoiceDate).isAfter(dayjs(b.invoiceDate)) ? 1 : -1,
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Net",
      dataIndex: "netTotal",
      sorter: (a, b) => a.netTotal.length - b.netTotal.length,
    },
  ];

  const Itemwisecolumns = [
    {
      title: "Sl No.",
      dataIndex: "slno",
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Bill No",
      dataIndex: "entrynumber",
      sorter: (a, b) => a.entryNumber.length - b.entryNumber.length,
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      sorter: (a, b) => a.supplier.length - b.supplier.length,
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      sorter: (a, b) => a.itemName.length - b.itemName.length,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      sorter: (a, b) => a.quantity.length - b.quantity.length,
    },
    {
      title: "Rate",
      dataIndex: "rate",
      sorter: (a, b) => a.price.length - b.price.length,
    },
    {
      title: "Total",
      dataIndex: "total",
      sorter: (a, b) => a.netAmount.length - b.netAmount.length,
    },
    {
      title: "Discount",
      dataIndex: "discountTotal",
      sorter: (a, b) => a.discountTotal.length - b.discountTotal.length,
    },
    {
      title: "Tax",
      dataIndex: "tax",
    },
    {
      title: "Net",
      dataIndex: "netAmount",
      sorter: (a, b) => a.netAmount.length - b.netAmount.length,
    },
  ];
  const MySwal = withReactContent(Swal);

  // Handle the click event to delete a goods receipts
  const handleDeleteClick = (obj) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const objPurchase = new PurchaseClass();
          objPurchase.id = obj.id;
          objPurchase.guid = obj.guid;
          objPurchase.branch = branch;
          objPurchase.isDeleted = true;
          objPurchase.updatedUser = `{${userId?.id}}`; //"";
          const response = await deletePurchase(objPurchase);
          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The Goods Receipt has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              handlePurchaseData(); // Refresh the list
              setSearchText(""); // Clear the search text
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error(
            "Failed to fetch goods reciept",
            error?.response?.data?.Message || "something went wrong"
          );
          MySwal.fire({
            title: "Error!",
            text: error?.response?.data?.Message || "something went wrong",
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  const handleEditClick = async (guid) => {
    try {
      console.log("inside edit click");
      console.log(guid, "guid");

      const response = getPurchaseInvoiceByGuid(guid);
      console.log(response, "response.data");
      setUpdateData(response);
      setModalMode("edit");
      setModalOpen(true);
    } catch (error) {
      console.error(
        "Error fetching goods receipt by GUID",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  function getPurchaseInvoiceByGuid(guid) {
    console.log(updateData, "updated data");

    const filteredPurchase = purchaseData.filter(
      (purchase) => purchase.guid === guid
    );
    console.log(filteredPurchase[0], "filteredPurchase[0]");
    return filteredPurchase[0];
  }
  const handleTabChange = (key) => {
    console.log("Active Tab:", key);
    if (key == 1) {
      setIsFilterVisible(false);
    } else {
      setIsFilterVisible(true);
    }
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
    setToDate(null);
    console.log(date, "from date");
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    console.log(date, "to date");
  };

  const handleProductChange = (selectedOption) => {
    console.log("Selected Product:", selectedOption);
    setSelectedProduct(selectedOption);
  };

  const handleSupplierChange = (selectedOption) => {
    console.log("Selected Supplier:", selectedOption);
    setSelectedSupplier(selectedOption);
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Goods Receipt</h4>
                <h6>Manage goods receipt notes here</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link
                    onClick={() => exportPDF(purchaseData, "Goods Receipt")}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link
                    onClick={() => exportExcel(purchaseData, "Goods Receipt")}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link
                    onClick={() => HandlePrint(purchaseData, "Goods Receipt")}
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => handlePurchaseData()}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                onClick={() => {
                  setModalMode("add"); // Set the modal mode to 'add'
                  setModalOpen(true); // Open the modal
                }}
              >
                <PlusCircle className="me-2" />
                New
              </Link>
            </div>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      aria-controls="DataTables_Table_0"
                      value={searchText}
                      onChange={handleSearch}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks ">
                        <label>From Date</label>
                        <div className="input-groupicon calender-input">
                          <Calendar className="info-img" />
                          <DatePicker
                            className="datetimepicker"
                            id="from-date"
                            placeholderText="From Date"
                            // selected={fromDate}
                            value={fromDate}
                            onChange={handleFromDateChange}
                            startDate={fromDate}
                            selectsStart
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <label>To Date</label>
                        <div className="input-groupicon calender-input">
                          <Calendar className="info-img" />
                          <DatePicker
                            id="to-date"
                            placeholderText="To Date"
                            value={toDate}
                            onChange={handleToDateChange}
                            minDate={fromDate}
                            startDate={fromDate}
                            selectsEnd
                            disabled={!fromDate}
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks ">
                        <label>Supplier</label>
                        <div className="input-groupicon calender-input">
                          <Smile className="info-img" />
                          <Select
                            className="img-select"
                            options={formatedsupplierList}
                            value={selectedSupplier}
                            onChange={handleSupplierChange}
                            classNamePrefix="react-select"
                            placeholder="suppliers"
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks ">
                        <label>Products</label>
                        <div className="input-groupicon calender-input">
                          {/* <Layers className="info-img" /> */}
                          <Select
                            className="img-select"
                            options={formatedproductList}
                            value={selectedProduct}
                            onChange={handleProductChange}
                            classNamePrefix="react-select"
                            placeholder="Products"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks ">
                        <label>Supplier</label>
                        <div className="input-groupicon calender-input">
                          {/* <Smile className="info-img" /> */}
                          <Select
                            className="img-select"
                            options={formatedsupplierList}
                            value={selectedSupplier}
                            onChange={handleSupplierChange}
                            classNamePrefix="react-select"
                            placeholder="suppliers"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-2 col-sm-6 col-12 ms-auto">
                    <div className="input-blocks">
                      <button
                        className="btn btn-filters ms-auto"
                        onClick={handleFilter}
                      >
                        <i data-feather="search" className="feather-search" />{" "}
                        Search
                      </button>
                      {/* <Link className="btn btn-filters ms-auto">
                        <i data-feather="search" className="feather-search" />{" "}
                        Search{" "}
                      </Link> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                  <TabPane tab="Purchase Return" key="1">
                    <Table columns={columns} dataSource={filteredData} />
                  </TabPane>
                  <TabPane tab="Detailed Reports" key="2">
                    <Table columns={Reportcolumns} dataSource={filteredData} />
                  </TabPane>
                  <TabPane tab="Supplier Reports" key="3">
                    <Table
                      columns={supplierColumns}
                      dataSource={filteredData}
                    />
                  </TabPane>
                  <TabPane tab="Itemwise Reports" key="4">
                    <Table
                      columns={Itemwisecolumns}
                      dataSource={itemwiseData}
                    />
                  </TabPane>
                </Tabs>
                {/* <Table columns={columns} dataSource={filteredData} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Reason Modal */}
      {modalOpen && (
        <GoodsReceiptModal
          mode={modalMode}
          data={modalMode === "edit" ? updateData : {}}
          handleClose={() => setModalOpen(false)}
          handleRefresh={() => {
            handlePurchaseData(); // Refresh the list
            setSearchText(""); // Clear the search text
          }}
        />
      )}
    </div>
  );
};

export default GoodsReceipt;
