import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { DatePicker } from "antd";
import Select from "react-select";
import {
  Calendar,
  MinusCircle,
} from "feather-icons-react/build/IconComponents";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import { getProductList } from "../../../services/MasterApiServices";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import toast from "react-hot-toast";
import { ProductClass } from "../../../core/json/Product";
import { getUnitList } from "../../../services/MasterApiServices";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import {
  getStockByBranch,
  deleteStock,
  addEditStock,
} from "../../../services/StockApiServices";
import { InvStockAdjustment } from "../../../core/json/invStockAdjust";
import { InvStockAdjustmentDetail } from "../../../core/json/invStockAdjustDetail";
import { useSelector, useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { fetchProduct } from "../../../redux/userSetting";

const StockAdjustmentModal = ({ mode, data, handleClose, handleRefresh }) => {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      date: dayjs().format("YYYY-MM-DD"),
      deliveryDate: dayjs().format("YYYY-MM-DD"),
      invoiceDate: dayjs().format("YYYY-MM-DD"),
      duedate: dayjs().format("YYYY-MM-DD"),
      round: 0,
      subtraction: 0,
      addition: 0,
    },
  });

  const [rows, setRows] = useState([]);
  const [rowId, setRowId] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [unitData, setunitData] = useState([]);
  const [products, setProducts] = useState([]);
  const { branch } = useSelector((state) => state.branchlist);
  const { userId } = useSelector((state) => state.userauth);
  const dispatch = useDispatch();
  const { unitList, productList } = useSelector((state) => state.usersetting);

  const handleIncrease = (index) => {
    const currentQuantity = getValues(`row.${index}.adjQuantity`);
    const newQuantity = Number(currentQuantity || 0) + 1;
    setValue(`row.${index}.adjQuantity`, newQuantity);
  };

  const handleDecrease = (index) => {
    const currentQuantity = getValues(`row.${index}.adjQuantity`);
    const newQuantity = Number(currentQuantity || 1) - 1;
    setValue(`row.${index}.adjQuantity`, newQuantity);
  };

  const type = [
    { value: 0, label: "Stock Adjustment" },
    { value: 1, label: "Damage" },
    { value: 2, label: "Wastage" },
  ];

  function addRowFunction() {
    setRows([
      ...rows,
      {
        id: rowId,
      },
    ]);
    setRowId(rowId + 1);
  }
  //function to add a new row
  const addRow = () => {
    addRowFunction();
  };

  const MySwal = withReactContent(Swal);
  // Handle the click event to delete ""
  const handleDeleteClick = (id, index) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const updatedRows = rows?.filter((row) => {
            return row.id !== id;
          });
          setRows(updatedRows);
          const currentRows = getValues("row");
          const updatedFormRows = currentRows.filter((_, i) => i !== index);
          setValue("row", updatedFormRows);

          if (updatedRows) {
            MySwal.fire({
              title: "Deleted!",
              text: "The item has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {});
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error(
            "Error deleting :",
            error?.response?.data?.Message || "something went wrong"
          );
          MySwal.fire({
            title: "Error!",
            text: error?.response?.data?.Message || "something went wrong",
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };
  const handleUnitList = async () => {
    try {
      const response = await getUnitList();
      const formattedUnit = response?.map((unit) => ({
        label: unit.name,
        value: unit.guid,
      }));
      setunitData(formattedUnit);
    } catch (error) {
      console.error(
        "Error fetching unit",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  const handleEachProductfetch = async (selectedOption, rowId) => {
    setValue(`row.${rowId}.product`, selectedOption);

    try {
      const productData = await getSingleProduct(selectedOption.value);
      console.log(productData);
      setValue(`row.${rowId}.code`, productData?.code || 0);
      setValue(`row.${rowId}.hsnCode`, productData?.hsnCode || 0);
      setValue(`row.${rowId}.unit`, productData?.unit.guid || 0);
    } catch (error) {
      console.error(
        "Error fetching product by GUID:",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  async function getSingleProduct(guid) {
    try {
      //const response = await getProductList(branch);
      const filteredData = productList?.find(
        (product) => product.guid === guid
      );
      return filteredData;
    } catch (error) {
      console.error(
        "Error fetching products",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  }

  // Fetch purchase invoices data
  const handleEntryNo = async () => {
    try {
      const response = await getStockByBranch(branch);
      const stockData = response?.data || [];
      console.log("stockData", stockData);

      const maxno =
        stockData.length > 0
          ? stockData
              .map((data) => parseInt(data.no.replace(/\D/g, "")))
              .reduce((max, num) => Math.max(max, num), 0)
          : 0;
      let counter = maxno + 1;
      console.log("counter", counter);

      setValue(`no`, `${counter}`);
    } catch (error) {
      console.error(
        "Failed to fetch stock adjustments",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };
  useEffect(() => {
    if (mode === "edit" && data) {
      setValue("no", data.no);
      setValue("remarks", data.remarks);
      setValue("type", data.type);
      setValue("date", dayjs(data.date));
      console.log(data, "daaaaaataaa");

      //   data?.purchaseDetails?.forEach((detail, index) => {
      //     addRowFunction();
      //     setValue(`row.${index}.product`, detail.product.guid);
      //     setValue(`row.${index}.batch`, detail.batch);
      //     setValue(`row.${index}.unit`, detail.unit.guid);
      //     setValue(`row.${index}.quantity`, detail.quantity);
      //     setValue(`row.${index}.adjQuantity`, detail.adjQuantity);
      //   });
      // }
      data?.stockAdjustmentDetails?.forEach((detail, index) => {
        addRowFunction(); // Add rows before setting values

        setValue(`row.${index}.product`, detail.product.guid);
        setValue(`row.${index}.batch`, detail.batch);
        setValue(`row.${index}.unit`, detail.unit.guid);
        setValue(`row.${index}.baseQuantity`, detail.baseQuantity);
        setValue(`row.${index}.adjQuantity`, detail.adjQuantity);
      });
    }
    // }
    if (mode === "add") {
      handleEntryNo();
      addRowFunction();
    }
    handleProductsList();
    dispatch(fetchProduct(branch));
    handleUnitList();
  }, []);

  async function getUnitByGuid(guid) {
    const unitData = await getUnitList();
    const filteredData = unitData.filter((unit) => unit.guid === guid);
    return filteredData[0];
  }
  const handleProductsList = async () => {
    try {
      //const response = await getProductList(branch);
      const response = productList || [];
      console.log(response, "response-------------");
      const formattedProducts = response?.map((product) => ({
        label: product.name,
        value: product.guid,
      }));
      setProducts(formattedProducts);
    } catch (error) {
      console.error(
        "Error fetching products",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  async function getProductByGuid(guid) {
    const filteredData = productList.filter((product) => product.guid === guid);
    return filteredData[0];
  }

  const handleFormSubmit = async (formdata) => {
    console.log("inside form submit");

    const objStockAdj = new InvStockAdjustment();
    if (mode === "edit") {
      console.log("edit mode on--------");
      console.log(data.guid, "data.guid");
      objStockAdj.guid = data.guid;
      objStockAdj.id = data.id;
    }
    objStockAdj.branch = branch;
    objStockAdj.date = formdata.date
      ? dayjs(formdata.date).format("YYYY-MM-DDTHH:mm:ss")
      : null;
    objStockAdj.type = formdata.type;
    objStockAdj.updatedUser = `{${userId?.id}}`;
    objStockAdj.remarks = formdata.remarks || "";
    objStockAdj.no = formdata.no || 0;
    var detailsdata;
    for (const row of formdata.row) {
      const objDetail = new InvStockAdjustmentDetail();
      detailsdata = await getProductByGuid(row?.product?.value || row?.product);
      let ObjProdClass = new ProductClass();
      ObjProdClass.guid = detailsdata?.guid;
      objDetail.product = ObjProdClass;
      const unitValue = row.unit;
      if (unitValue) {
        const unit = await getUnitByGuid(unitValue);
        objDetail.unit = unit;
      }
      if (type === 0 && objDetail.baseQuantity > objDetail.adjQuantity) {
        objDetail.dedQty = objDetail.baseQuantity - objDetail.adjQuantity;
        objDetail.addQty = 0;
      } else if (type === 0 && objDetail.baseQuantity < objDetail.adjQuantity) {
        objDetail.addQty = objDetail.adjQuantity - objDetail.baseQuantity;
        objDetail.dedQty = 0;
      } else {
        objDetail.baseQuantity = row.baseQuantity || 0;
        objDetail.adjQuantity = row.adjQuantity || 0;
      }
      objDetail.batch = row.batch || "0";
      objStockAdj.stockAdjustmentDetails.push(objDetail);
    }
    try {
      console.log(objStockAdj, "-------------------objStockAdj");
      const response = await addEditStock(objStockAdj);
      if (response) {
        toast.success("Success");
        handleRefresh();
        handleClose();
      }
    } catch (error) {
      toast.error(error?.response?.data?.Message || "something went wrong");
      console.error(
        "Error handling :",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  return (
    <div>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
      >
        <div className="modal-dialog add-centered">
          <div className="modal-content">
            <div className="page-wrapper p-0 m-0">
              <div className="content p-0">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>
                      {" "}
                      {mode === "edit"
                        ? "Edit Stock Adjustments"
                        : "Add Stock Adjustments"}
                    </h4>
                  </div>
                  <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                      <div className="row">
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Entry No.</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("no")}
                              required
                              //disabled
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>
                              Date
                              <span className="text-danger">*</span>
                            </label>
                            <div className="input-groupicon calender-input">
                              <Calendar className="info-img" />
                              <Controller
                                control={control}
                                name="date"
                                render={({ field: { onChange, value } }) => (
                                  <DatePicker
                                    className="datetimepicker"
                                    value={value ? dayjs(value) : dayjs()}
                                    format="DD-MM-YYYY"
                                    onChange={(date) =>
                                      onChange(
                                        date ? date.format("YYYY-MM-DD") : null
                                      )
                                    }
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">
                              Type<span className="text-danger">*</span>
                            </label>
                            <div className="row">
                              <Controller
                                control={control}
                                name={`type`}
                                render={({ field: { onChange, value } }) => (
                                  <Select
                                    classNamePrefix="react-select"
                                    options={type}
                                    value={type?.find(
                                      (type) => type?.value === value
                                    )}
                                    onChange={(selectedOption) =>
                                      onChange(selectedOption.value)
                                    }
                                    required
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Remarks</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("remarks")}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="table-responsive  no-pagination table-container">
                        <table className="table tablezind">
                          <thead>
                            <tr>
                              <th className="sticky-header">
                                Description
                                <span className="text-danger">*</span>
                              </th>
                              <th className="sticky-header">
                                Unit<span className="text-danger">*</span>
                              </th>
                              <th className="sticky-header">Batch</th>
                              <th className="sticky-header">
                                Quantity<span className="text-danger">*</span>
                              </th>
                              <th className="sticky-header">
                                AdjustQuantity
                                <span className="text-danger">*</span>
                              </th>
                              <th className="sticky-header">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rows?.map((row, index) => {
                              return (
                                <tr key={row.id}>
                                  <td>
                                    <Controller
                                      control={control}
                                      name={`row.${index}.product`}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <Select
                                          classNamePrefix="react-select"
                                          options={products}
                                          value={products?.find(
                                            (option) => option.value === value
                                          )}
                                          onChange={(selectedOption) => {
                                            onChange(selectedOption.value);
                                            handleEachProductfetch(
                                              selectedOption,
                                              index
                                            );
                                          }}
                                          styles={{
                                            menu: (provided) => ({
                                              ...provided,
                                              zIndex: 9999,
                                              position: "relative",
                                            }),
                                          }}
                                          required
                                        />
                                      )}
                                    />
                                  </td>
                                  <td>
                                    <Controller
                                      control={control}
                                      name={`row.${index}.unit`}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <Select
                                          classNamePrefix="react-select"
                                          options={unitData}
                                          value={unitData?.find(
                                            (option) => option.value === value
                                          )}
                                          onChange={(selectedOption) => {
                                            onChange(selectedOption.value);
                                          }}
                                          isDisabled={true}
                                        />
                                      )}
                                    />
                                  </td>

                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`row.${index}.batch`)}
                                    />
                                  </td>

                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`row.${index}.baseQuantity`)}
                                      required
                                    />
                                    {errors.row?.[index]?.quantity && (
                                      <p className="text-danger">
                                        {errors.row[index].quantity.message}
                                      </p>
                                    )}
                                  </td>
                                  <td>
                                    <div className="product-quantity">
                                      <span
                                        className="quantity-btn"
                                        onClick={() => handleDecrease(index)}
                                      >
                                        <MinusCircle className="feather-search" />
                                      </span>
                                      <input
                                        style={{ minWidth: "3rem" }}
                                        type="text"
                                        className="form-control"
                                        defaultValue={1}
                                        {...register(
                                          `row.${index}.adjQuantity`
                                        )}
                                      />
                                      {errors.row?.[index]?.quantity && (
                                        <p className="text-danger">
                                          {errors.row[index].quantity.message}
                                        </p>
                                      )}
                                      <span
                                        className="quantity-btn"
                                        onClick={() => handleIncrease(index)}
                                      >
                                        <PlusCircle className="plus-circle" />
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <Link
                                      className="confirm-text p-2"
                                      onClick={() =>
                                        handleDeleteClick(row.id, index)
                                      }
                                    >
                                      <i
                                        data-feather="trash-2"
                                        className="feather-trash-2"
                                      ></i>
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="col-lg-2 col-sm-2 col-2 ps-0 ">
                        <button
                          className=" btn btn-dark add-icon tab  mt-3 "
                          type="button"
                          onClick={addRow}
                        >
                          <PlusCircle className="feather-plus-circles" />
                        </button>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 text-end">
                          <button
                            type="button"
                            className="btn btn-cancel add-cancel me-3"
                            // onClick={() => resetmodal()}
                          >
                            Reset
                          </button>
                          <button
                            className="btn btn-submit add-sale"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StockAdjustmentModal;
