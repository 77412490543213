import axios from "axios";

const base_path = process.env.REACT_APP_API_URL;

//Get stock adjustment by branch
// export const getStockByBranch = async (branch) => {
//   console.log("branch", branch);

//   const response = axios.get(
//     `${base_path}/Api/InvStockAdjustment/GetAllByBranchID?globalBranchID=${branch?.guid}`
//   );
//   return response;
// };
export const getStockByBranch = async (branch) => {
  console.log("branch", branch);
 
  const response = axios.get(
    `${base_path}/Api/InvStockAdjustment/GetAllByBranchID?globalBranchID=${branch?.guid}`
  );
  return response;
};

// Create a new or edit stock adjustment
export const addEditStock = async (stockData) => {
  console.log(JSON.stringify(stockData), "---------stockData--");
  console.log(stockData, "---------stockData--");
  const response = await axios.post(
    `${base_path}/Api/InvStockAdjustment/SaveOrUpdate`,
    JSON.stringify(stockData)
  );
  return response;
};

//delete stock adjustment
export const deleteStock = async (stockData) => {
  console.log(stockData,"xxxxxxx");
  const response = await axios.post(
    `${base_path}/Api/InvStockAdjustment/Delete`,
    stockData
  );
  return response;
};

export const getproductionbybranch = async (branch) => {
 
  const response = axios.get(
    `${base_path}/Api/InvProduction/GetAllByBranchID?globalBranchID=${branch?.guid}`
  );
  return response;
};
export const saveproduction = async (data) => {
  const response = await axios.post(`${base_path}/Api/InvProduction/SaveOrUpdate`,
    data
  );
  return response.data;
};